import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

const users = [...Array(24)].map(() => ({
  id: faker.datatype.uuid(),
  tg_id: faker.datatype.number(100000000, 5000000000),
  balance: faker.datatype.number(70000),
  ref: faker.datatype.number(100000000, 5000000000),
  ref_income: faker.datatype.number(3000),
  tickets: faker.datatype.number(20),
  weekly_coins: faker.datatype.number(2000),
  weekly_refs: faker.datatype.number(10),
  stop: sample(['alive', 'stopped']),
  ban: sample(['alive', 'banned']),
  first_name: faker.name.firstName(),
  last_name: faker.name.lastName(),
  username: faker.internet.userName(),
}));

export default users;
