import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import User from './pages/User';
import TCBDashboardApp from './pages/TCBDashboardApp';
import Page404 from './pages/Page404';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/takecoinsbot" /> },
        { path: '/dashboard/takecoinsbot', element: <TCBDashboardApp /> },
        { path: '/dashboard/user', element: <User /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
