import jwtDecode from 'jwt-decode';

function handleCallbackResponse(response) {
  const userdata = jwtDecode(response.credential);
  console.log(userdata);
}

export default function auth() {
  /* global google */
  google.accounts.id.initialize({
    client_id: '375086086246-d0gll4u5hbnkom698m4ei2aia62v9c2s.apps.googleusercontent.com',
    callback: handleCallbackResponse,
  });

  google.accounts.id.renderButton(
    document.getElementById('google-auth'),
    {
      theme: 'outline',
      size: 'large',
    },
  );
}
