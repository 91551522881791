import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function useResponsive(query, key, start, end) {
  const theme = useTheme();
  if (query === 'up') return useMediaQuery(theme.breakpoints.up(key));
  if (query === 'down') return useMediaQuery(theme.breakpoints.down(key));
  if (query === 'between') return useMediaQuery(theme.breakpoints.between(start, end));
  if (query === 'only') return useMediaQuery(theme.breakpoints.only(key));
  return null;
}
