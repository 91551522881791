import React from 'react';
import Router from './routes';
import ThemeProvider from './theme';
import { BaseOptionChartStyle } from './components/chart';

export default function App() {
  return (
    <ThemeProvider>
      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
  );
}
