import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { AccountBalance, ArrowDownward, ArrowUpward, ConfirmationNumber, Diamond, Inventory2, PeopleAlt, Toll } from '@mui/icons-material';
import Page from '../components/Page';
import { AppCurrentVisits, AppWebsiteVisits, AppWidgetSummary, AppCurrentSubject, AppConversionRates } from '../sections/@dashboard/app';

export default function DashboardApp() {
  const [stats, setStats] = useState({ users: [], usersAlive: [], usersActive: [], coinsTotalSupply: [], diamondsTotalSupply: [], usersDates: [], coinsTotalSupplyDates: [], diamondsTotalSupplyDates: [] });
  useEffect(() => {
    const getStats = async () => {
      const tmpStats = await (await fetch('https://api.avastium.dev/takecoinsbot/getStats', { method: 'POST', headers: { 'Content-Type': 'application/json' } })).json();
      const usersDates = [];
      const coinsTotalSupplyDates = [];
      const diamondsTotalSupplyDates = [];
      for (let i = 0; i < tmpStats.users.length; i += 1) {
        const date = new Date(1665446400000 + i * 86400000);
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        usersDates.push(`${month}/${day}/${year}`);
      }
      for (let i = 0; i < tmpStats.coinsTotalSupply.length; i += 1) {
        const date = new Date(1665446400000 + i * 86400000);
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        coinsTotalSupplyDates.push(`${month}/${day}/${year}`);
      }
      for (let i = 0; i < tmpStats.diamondsTotalSupply.length; i += 1) {
        const date = new Date(1677020400000 + i * 86400000);
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        diamondsTotalSupplyDates.push(`${month}/${day}/${year}`);
      }
      tmpStats.usersDates = usersDates;
      tmpStats.coinsTotalSupplyDates = coinsTotalSupplyDates;
      tmpStats.diamondsTotalSupplyDates = diamondsTotalSupplyDates;
      setStats(tmpStats);
    };
    getStats();
  }, [stats.length]);

  const [usersAmount, setUsersAmount] = useState([0, 0, 0, 0]);
  useEffect(() => {
    const getUsersAmount = async () => setUsersAmount(await (await fetch('https://api.avastium.dev/takecoinsbot/usersAmount', { method: 'POST', headers: { 'Content-Type': 'application/json' } })).json());
    getUsersAmount();
  }, [usersAmount.length]);

  const [coinsReceipts, setCoinsReceipts] = useState(0);
  useEffect(() => {
    const getCoinsReceipts = async () => setCoinsReceipts(await (await fetch('https://api.avastium.dev/takecoinsbot/receipts', { method: 'POST', headers: { 'Content-Type': 'application/json' } })).json());
    getCoinsReceipts();
  }, [coinsReceipts]);

  const [coinsTotalSupply, setCoinsTotalSupply] = useState(0);
  useEffect(() => {
    const getCoinsTotalSupply = async () => setCoinsTotalSupply(await (await fetch('https://api.avastium.dev/takecoinsbot/coinsTotalSupply', { method: 'POST', headers: { 'Content-Type': 'application/json' } })).json());
    getCoinsTotalSupply();
  }, [coinsTotalSupply]);

  const [diamondsTotalSupply, setDiamondsTotalSupply] = useState(0);
  useEffect(() => {
    const getDiamondsTotalSupply = async () => setDiamondsTotalSupply(await (await fetch('https://api.avastium.dev/takecoinsbot/diamondsTotalSupply', { method: 'POST', headers: { 'Content-Type': 'application/json' } })).json());
    getDiamondsTotalSupply();
  }, [diamondsTotalSupply]);

  const [totalDeposited, setTotalDeposited] = useState(0);
  useEffect(() => {
    const getTotalDeposited = async () => setTotalDeposited(await (await fetch('https://api.avastium.dev/takecoinsbot/totalDeposited', { method: 'POST', headers: { 'Content-Type': 'application/json' } })).json());
    getTotalDeposited();
  }, [totalDeposited]);

  const [totalWithdrawn, setTotalWithdrawn] = useState(0);
  useEffect(() => {
    const getTotalWithdrawn = async () => setTotalWithdrawn(await (await fetch('https://api.avastium.dev/takecoinsbot/totalWithdrawn', { method: 'POST', headers: { 'Content-Type': 'application/json' } })).json());
    getTotalWithdrawn();
  }, [totalWithdrawn]);

  const [casesOpened, setCasesOpened] = useState([0, 0, 0, 0, 0, 0, 0]);
  useEffect(() => {
    const getCasesOpened = async () => setCasesOpened(await (await fetch('https://api.avastium.dev/takecoinsbot/casesOpened', { method: 'POST', headers: { 'Content-Type': 'application/json' } })).json());
    getCasesOpened();
  }, [casesOpened.length]);

  const [gamesImpact, setGamesImpact] = useState([0, 0, 0, 0, 0, 0, 0]);
  useEffect(() => {
    const getGamesImpact = async () => setGamesImpact(await (await fetch('https://api.avastium.dev/takecoinsbot/gamesImpact', { method: 'POST', headers: { 'Content-Type': 'application/json' } })).json());
    getGamesImpact();
  }, [gamesImpact.length]);

  const [lotteryTickets, setLotteryTickets] = useState(0);
  useEffect(() => {
    const getLotteryTickets = async () => setLotteryTickets(await (await fetch('https://api.avastium.dev/takecoinsbot/lotteryTickets', { method: 'POST', headers: { 'Content-Type': 'application/json' } })).json());
    getLotteryTickets();
  }, [lotteryTickets]);

  const theme = useTheme();

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>TakeCoinsBot Dashboard</Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Users" total={usersAmount[0]} icon={<PeopleAlt sx={{ fontSize: 40 }} />} />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Coins Receipts" total={coinsReceipts} color="success" icon={<Toll sx={{ fontSize: 40 }} />} />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Coins Total Supply" total={coinsTotalSupply} color="info" icon={<AccountBalance sx={{ fontSize: 40 }} />} />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Diamonds Total Supply" total={diamondsTotalSupply} color="info" icon={<Diamond sx={{ fontSize: 40 }} />} />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Cases Opened" total={casesOpened[0]} color="error" icon={<Inventory2 sx={{ fontSize: 40 }} />} />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Lottery Tickets" total={lotteryTickets} color="info" icon={<ConfirmationNumber sx={{ fontSize: 40 }} />} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Users"
              chartLabels={stats.usersDates}
              chartData={[
                {
                  name: 'Users', type: 'area', fill: 'gradient', data: stats.users,
                },
                {
                  name: 'Alive users', type: 'line', fill: 'solid', data: stats.usersAlive,
                },
                {
                  name: 'Active users', type: 'line', fill: 'solid', data: stats.usersActive,
                },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.success.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Total Deposited" format="currency" total={totalDeposited} color="success" icon={<ArrowDownward sx={{ fontSize: 40 }} />} />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Total Withdrawn" format="currency" total={totalWithdrawn} color="error" icon={<ArrowUpward sx={{ fontSize: 40 }} />} />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <AppCurrentVisits
              title="Users' states"
              chartData={[
                { label: 'alive', value: usersAmount[1] },
                { label: 'stopped', value: usersAmount[2] },
                { label: 'banned', value: usersAmount[3] },
              ]}
              chartColors={[
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <AppCurrentSubject
              title="Top Cases"
              chartLabels={['Free', 'Standard', 'Premium', 'Secret']}
              chartData={[
                { name: 'Games', data: [casesOpened[1], casesOpened[2], casesOpened[3], casesOpened[4]] },
              ]}
              chartColors={[
                theme.palette.info.main,
                theme.palette.info.main,
                theme.palette.info.main,
                theme.palette.info.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppConversionRates
              title="Games Impact"
              subheader="Impact by game"
              chartData={[
                { label: 'Dice', value: gamesImpact[1] },
                { label: 'Darts', value: gamesImpact[2] },
                { label: 'Basketball', value: gamesImpact[3] },
                { label: 'Football', value: gamesImpact[4] },
                { label: 'Bowling', value: gamesImpact[5] },
                { label: 'Slots', value: gamesImpact[6] },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <AppWebsiteVisits
              title="Coins Total Supply"
              chartLabels={stats.coinsTotalSupplyDates}
              chartData={[
                {
                  name: '', type: 'area', fill: 'gradient', data: stats.coinsTotalSupply,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <AppWebsiteVisits
              title="Diamonds Total Supply"
              chartLabels={stats.diamondsTotalSupplyDates}
              chartData={[
                {
                  name: '', type: 'area', fill: 'gradient', data: stats.diamondsTotalSupply,
                },
              ]}
            />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
}
